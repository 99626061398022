<template>
    <div>
        <page-title :heading="$t('erp.lang_stornogruende') " :subheading="$t('erp.lang_stornogruende')" :icon=icon></page-title>
        <div class="app-main__inner">
        <VoidReasons></VoidReasons>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import VoidReasons from "../../../components/erp/settings/VoidReasons";

    export default {
        components: {
            PageTitle,
            VoidReasons
        },
        data: () => ({
            icon: 'pe-7s-refresh-2 icon-gradient bg-tempting-azure',
        })
    }
</script>